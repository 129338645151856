<template>
  <section id="Contact">
    <div id="contact"></div>
    <div className="flex justify-center">
      <img src="../assets/qualiopi.png" className="w-[20rem]" alt="Certif">
    </div>
    <div data-popover="" id="Email-popover" role="tooltip"
         className="inline-block absolute invisible z-10 w-64 text-sm font-light rounded-lg border shadow-sm opacity-0 transition-opacity duration-300 text-gray-400 border-gray-600 bg-gray-800"
         style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 10px, 0px);"
         data-popper-reference-hidden="" data-popper-escaped="" data-popper-placement="bottom">
      <div className="py-2 px-3 rounded-t-lg border-b  border-gray-600 bg-gray-700">
        <h3 className="font-semibold text-white">Email Crea</h3>
      </div>
      <div className="py-2 px-3">
        <p>Si tu click tu sera rediriger sur ton app Email !</p>
      </div>
      <div data-popper-arrow="" style="position: absolute; left: 0px; transform: translate3d(0px, 0px, 0px);"></div>
    </div>
    <div data-popover="" id="Phone-popover" role="tooltip"
         className="inline-block absolute invisible z-10 w-64 text-sm font-light rounded-lg border shadow-sm opacity-0 transition-opacity duration-300 text-gray-400 border-gray-600 bg-gray-800"
         style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 10px, 0px);"
         data-popper-reference-hidden="" data-popper-escaped="" data-popper-placement="bottom">
      <div className="py-2 px-3 rounded-t-lg border-b border-gray-600 bg-gray-700">
        <h3 className="font-semibold text-white">Numéro Crea</h3>
      </div>
      <div className="py-2 px-3">
        <p>Si tu click Le numéro sera copié !</p>
      </div>
      <div data-popper-arrow="" style="position: absolute; left: 0px; transform: translate3d(0px, 0px, 0px);"></div>
    </div>
  </section>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "VueContact",
  components: {},
  mounted() {
    // create a contact info
    const contactInfo = document.createElement('div');
    contactInfo.classList.add('container', 'mx-auto', 'px-4', 'pb-8', 'md:py-8', 'lg:py-8', 'flex', 'flex-col', 'justify-center', 'items-center', 'text-center');
    // language=HTML
    contactInfo.innerHTML = `
      <h2 class="text-3xl font-bold text-white">Contactez Nous</h2>
      <p class="text-lg text-gray-500 mt-4">Vous avez des questions ? N'hésitez pas à nous contacter directement. Notre équipe vous répondra dans les plus brefs délais.</p>
      <div class="flex flex-col md:flex-row mt-8">
          <div class="flex flex-col items-center md:items-start md:mr-8">
            <div class="flex items-center">
              <svg id="Mail" class="w-6 h-6 text-[#e51c23]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
              <span id="Mail" class="ml-2 text-white">Adresse Mail</span>
            </div>
            <span id="Mail" class="Email text-gray-300 mt-2">contact@Creaformations-france.fr</span>
          </div>
          <div class="flex flex-col items-center md:items-start mt-4 md:mt-0">
            <div class="flex items-center">
              <svg id="Phone" class="w-6 h-6 text-[#e51c23]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path></svg>
              <span id="Phone" class="ml-2 text-white">Téléphone</span>
            </div>
            <span id="Phone" class="NumbeCREA text-gray-300 mt-2 mx-2">+33 3 69 32 28 93</span>
          </div>
        </div>
      </div>
    `;
    contactInfo.id = 'contact-info';
    document.getElementById('contact').appendChild(contactInfo);

    // if click on mail icon or mail text, copy mail
    document.querySelectorAll('#Mail').forEach((element) => {
      element.addEventListener('mouseover', () => {
        element.style.cursor = 'pointer';
      });
      // add data-popover-target on element
      document.querySelector('.Email').setAttribute('data-popover-target', 'Email-popover');
      element.addEventListener('click', () => {
        // get text on span id="Email"
        const mail = document.querySelector('.Email').innerText;
        navigator.clipboard.writeText('' + mail);
        // copy mail and paste on new tab
        window.open(`mailto:${mail}`);
        // create popup to confirm copy mail
        const popup = document.createElement('div');
        popup.classList.add('fixed', 'top-0', 'left-0', 'w-full', 'h-full', 'flex', 'justify-center', 'items-center', 'bg-black', 'bg-opacity-50', 'z-50');
        popup.innerHTML = `
          <div class="bg-gray-900 rounded-lg shadow-lg p-4">
            <div class="flex justify-between items-center">
              <div class="flex items-center">
                <svg class="w-6 h-6 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                <span class="font-bold text-green-500">Succès</span>
              </div>
              <button id="close-popup" class="text-gray-400 hover:text-gray-600 focus:outline-none focus:text-gray-600">
                <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
              </button>
            </div>
            <div class="mt-4">
              <p class="text-gray-100 font-semibold">L'adresse mail a été copié dans le presse-papier</p>
            </div>
          </div>
        `;
        document.body.appendChild(popup);
        // close popup
        document.getElementById('close-popup').addEventListener('click', () => {
          popup.remove();
        });
        setTimeout(() => {
          popup.remove();
        }, 3000);
      });
    });

    // if click on phone icon or phone text, copy phone to clipboard
    document.querySelectorAll('#Phone').forEach((element) => {
      element.addEventListener('mouseover', () => {
        element.style.cursor = 'pointer';
      });
      let number = document.querySelector('.NumbeCREA');
      number.setAttribute('data-popover-target', 'Phone-popover');
      element.addEventListener('click', () => {
        let phone = document.getElementById('Phone');
        phone.addEventListener('mouseover', () => {
          phone.style.cursor = 'pointer';
        });
        navigator.clipboard.writeText('' + number.innerText);
        // create popup to confirm copy phone
        const popup = document.createElement('div');
        popup.classList.add('fixed', 'top-0', 'left-0', 'w-full', 'h-full', 'flex', 'justify-center', 'items-center', 'bg-black', 'bg-opacity-50', 'z-50');
        popup.innerHTML = `
        <div class="bg-gray-900 rounded-lg shadow-lg p-4">
          <div class="flex justify-between items-center">
            <div class="flex items-center">
              <svg class="w-6 h-6 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
              <span class="font-bold text-green-500">Succès</span>
            </div>
            <button id="close-popup" class="text-gray-400 hover:text-gray-600 focus:outline-none focus:text-gray-600">
              <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
            </button>
          </div>
          <div class="mt-4">
            <p class="text-gray-100 font-semibold">Le numéro de téléphone a été copié dans le presse-papier</p>
          </div>
        </div>
      `;
        document.body.appendChild(popup);
        // close popup
        document.getElementById('close-popup').addEventListener('click', () => {
          popup.remove();
        });
        setTimeout(() => {
          popup.remove();
        }, 3000);
      });
    });
  },
}
</script>

<style scoped>
#contact {
  margin: auto;
}
</style>