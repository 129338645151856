import { createApp } from 'vue'
import App from './App.vue'

import './input.css';
import 'flowbite';

createApp(App).mount('#app')

const FirstLink = document.querySelectorAll('#FirstLink');
const SecondLink = document.querySelectorAll('#SecondLink');
const ThirdLink = document.querySelectorAll('#ThirdLink');
const LasdtLink = document.querySelectorAll('#LastLink');

const Home = document.getElementById('Home');
const About = document.getElementById('About');
const Formations = document.getElementById('Formations');
const Contact = document.getElementById('Contact');

FirstLink.forEach((link) => {
    link.addEventListener('click', () => {
        Home.scrollIntoView({ behavior: 'smooth' });
    });
});

SecondLink.forEach((link) => {
    link.addEventListener('click', () => {
        About.scrollIntoView({ behavior: 'smooth' });
    });
});

ThirdLink.forEach((link) => {
    link.addEventListener('click', () => {
        Formations.scrollIntoView({ behavior: 'smooth' });
    });
});

LasdtLink.forEach((link) => {
    link.addEventListener('click', () => {
        Contact.scrollIntoView({ behavior: 'smooth' });
    });
});
